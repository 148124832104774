import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useParams, useNavigate } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';

// Initialize Supabase client
const supabase = createClient('https://vcnilrslhazsijoxcayg.supabase.co', 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InZjbmlscnNsaGF6c2lqb3hjYXlnIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjI0NTk2ODUsImV4cCI6MjAzODAzNTY4NX0.vtuuOokNgYvDqmBihNR6jvKl_80LikNxjjTuL5lHaJs');

const DebateDisplay = () => {
  const { urlkey } = useParams();
  const navigate = useNavigate();
  const [debateId, setDebateId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDebateId = async () => {
      try {
        const { data, error } = await supabase
          .from('debateurls')
          .select('id')
          .eq('urlkey', urlkey)
          .single();

        if (error) throw error;

        if (data) {
          setDebateId(data.id);
          window.location.href = `https://www.debatearena.app/stored-debates/${data.id}`
        } else {
          setError('Debate not found');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (urlkey) {
      fetchDebateId();
    } else {
      setLoading(false);
    }
  }, [urlkey, navigate]);

  if (error) return <div>Error: {error}</div>;
  if (!urlkey) return <div>Please provide a URL key</div>;

  return !debateId && (<div>{} '404 Not found')</div>) ;
};

const App = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/:urlkey" element={<DebateDisplay />} />
          <Route path="/" element={<div>Please provide a URL key in the URL</div>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;